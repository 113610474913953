<template>
  <Bar
    id="bar-chart-id"
    ref="barChart"
    :chart-data="chartData"
    :chart-options="chartOptions"
    @click.native="clickHandler"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
  name: 'MBarChart',
  components: { Bar },
  data () {
    return {
      chartInstance: {}
    }
  },
  props: {
    chartData: {
      type: Object,
      default: null
    },
    chartOptions: {
      type: Object,
      default: null
    }
  },
  methods: {
    clickHandler (event) {
      const points = this.chartInstance
        .getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)

      this.setActiveElement(points[0]?.index)
    },
    setActiveElement (num = -1) {
      this.$emit('chartClick', num)
      const activeElements = num > -1
        ? [{ datasetIndex: 0, index: num }]
        : []
      this.chartInstance.setActiveElements(activeElements)
      this.chartInstance.update()
    },
    resetAllTooltips () {
      this.chartInstance.tooltip?.setActiveElements([]);
      this.chartInstance.update()
    }
  },
  mounted () {
    this.chartInstance = this.$refs.barChart.chart
    if (this.chartData?.datasets && this.chartData?.datasets[0]?.data?.length) {
      this.setActiveElement(new Date().getMonth())
    }
  }
}
</script>
